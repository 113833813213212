.body {
  font-family: 'Raleway', sans-serif;
  color:#333333;
}

.menu a:link { 
  color: #333333; 
}

.menu a​:active { 
  color: #333333; 
}

.menu a:visited {
  color: #333333;
}

.menu a:hover { 
  color: #2d5986; 
}

.header {
  display: flex;
  padding: 1.5em;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid #333333 1px;
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  z-index: 99999;
  top: 0px;
}

.header p {
  font-size: 20px;
}

.menu a {
  margin: 1em;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

p {
  font-size: 15px;
}

.title-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 3em;
}

.profile-image img {
  border-radius: 50%;
  width: 18em;
  margin: 2em;
}

.about-section {
  color: #333333;
  margin-left: 15%; 
  margin-right: 15%;
}

.about-header {
  margin-top: 2em;
}

.about-header p {
  font-size: 16px;
}

.tech-list {
  display: flex;
}

.tech-list ul {
  margin-right: 7em;
  font-size: 16px;
}

.resume-button button {
  border-radius: 5px;
  border: solid #333333 1px;
  background-color: #333333;
  height: 2.5em;
  width: 12em;
  margin-top: 2em;
}

.resume-button a {
  color: white;
}

.resume-button a:hover {
  color: #2d5986;
}

.placeholder-section {
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em;
}

.ind-project img{
  border-radius: 50%;
  width: 15em;
  margin: 2em;
  height: 14em;
  align-self:center;
  border: solid grey 1px;
}

.work-section {
  margin-left: 10%;
  margin-right: 10%;
}

.work-section h1 {
  color: #333333;
}

.ind-project {
  border: 1px solid grey;
  height: 34em;
  width: 22em;
  margin: 2em;
  padding:1em;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 15em;
}

.project-title {
  font-weight: bold;
}

.links {
  display: flex;
  max-height: 2em;
  justify-content: space-around;
}

.live-site-button {
  width: 12em;
}

.card-icon {
  width: 2em;
  color: #333333;
}

.card-icon:hover {
  color: #2d5986;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
} 

.contact-section {
  margin-left: 15%;
  margin-right: 15%;
}

.contact-header {
  margin-bottom: 1em;
}

.contact-text {
  text-align: center;
  font-size: 16px;
}

.icon {
  width: 2em;
  margin: 1em;
  color: #333333;
}

.contact-icons {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.icon:hover {
  color: #2d5986;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.copyright {
  text-align: right;
  margin-right: 2em;
}

@media only screen and (min-width : 321px) and (max-device-width : 480px) {
  .tech-list {
    display: flex;
  }

  .tech-list ul {
    margin-right: 5rem;
    font-size: 16px;
  }

  .resume-button{
    display: flex;
    justify-content: center;
  }
}
